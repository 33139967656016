export class Photo {
  public id: string = null;
  public mime: string = null;
  public path: string = null;
  public uploaded: string = null;

  constructor(id?: string | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }
}
