
import * as _ from 'lodash';
import { Location } from './location';
import { Answer } from './answer';
import { Mission } from './mission';


export class Task {
  public id: number = null
  public mission_id: number = null // mission id
  public title: string = null;
  public comment: string = null;
  public city: string = null;
  public address: string = null;
  public zipcode: string = null;
  public country_iso: string = null;
  public latitude: number = null;
  public longitude: number = null;
  public checker_id: number = null;
  public assigned_date: string = null;
  public deadline_date: string = null;
  public creation_date: string = null;
  public check_date: string = null;
  public status: string = null;
  public history: any = null;
  public created_at: string = null;
  public answer_by: string = null;

  public mission: Mission = null;

  constructor(id?: number | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'mission':
            this.mission = new Mission(data[key]);
            break;
          default:
            this[key] = data[key];
        }
      }
    }
  }

}
